body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vxt-bottom-bar .vxt-bottom-bar-actions ul .separator {
  display: none !important;
}

.vxt-bottom-bar .vxt-bottom-bar-actions .controls-left {
  width: 33.3% !important;
}
.vxt-bottom-bar .vxt-bottom-bar-actions .controls-center {
  width: 33.3% !important;
}
.vxt-bottom-bar .vxt-bottom-bar-actions .controls-right {
  width: 33.3% !important;
}

.vxt-conference-attendees .participant-bar span.name {
  max-width: 120px;
}

#video-detach-button {
  display: none !important;
}